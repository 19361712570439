var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0 pt-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shopRedeems,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalRedeems,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.statusesFilter},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;_vm.retrieveShopRedeems();}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('td',[_c('v-spacer')],1)]},proxy:true},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created).toLocaleString())+" ")]}},{key:"item.invoiceUrl",fn:function(ref){
    var item = ref.item;
return [(item.invoiceUrl != null)?_c('router-link',{attrs:{"to":{
            name: 'InvoiceViewer',
            params: { invoiceUrl: item.invoiceUrl, redeemId : item.id}
          }}},[_c('v-avatar',{attrs:{"color":"primary","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":"","disabled":_vm.loading}},[_vm._v("mdi-receipt ")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }