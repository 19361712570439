import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import WalletApiService from "@/core/services/api.service.wallet";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },
    name: "shop-redeems",
    data() {
        return {
            pageSizes : [10 , 20 , 50 , 100] ,
            totalRedeems : 0 ,
            pagination : {} ,
            shopId: null,
            loading: false,
            valid: false,
            isSaving: false,
            shopRedeems: [],
            shopsSearchInput: null,
            status: 'PENDING',
            requiredRule: [v => !!v || "Field is required"],
            walletApiInitiated : false ,
        };
    },
    watch : {
        pagination : {
            handler() {
                if (this.walletApiInitiated) this.retrieveShopRedeems() ();
            } ,
            deep : true
        }
    } ,
    async created() {
        this.loading = true;
        this.$log.debug ( "await init wallet service - start" );
        await WalletApiService.init();
        this.$log.debug ( "await init wallet service - end" );
        this.walletApiInitiated = true;
        this.shopId = this.$route.params.shopId;
        let title = "Redeems for shop: " + this.shopId;
        this.$store.dispatch(SET_BREADCRUMB, [{title: title}]);
        this.retrieveShopRedeems();
    },
    methods: {
        previousState() {
            this.$router.go(-1);
        },
        getRequestParams(
            shopId,
            status
        ) {
            let params = {};

            if (shopId) {
                params["shopId"] = shopId;
            }

            if (status) {
                params["status"] = status;
            }

            let sort;
            let sortBy = this.pagination.sortBy;
            let sortDesc = this.pagination.sortDesc;
            if (sortBy.length === 1 && sortDesc.length === 1) {
                sort = sortBy[0];
                if (sortDesc[0] === true) sort += ",desc";
            }
            params["sort"] = sort;
            params["page"] = this.pagination.page;
            params["size"] = this.pagination.itemsPerPage;

            return params;
        },
        retrieveShopRedeems() {
            const params = this.getRequestParams (
                this.shopId,
                this.status,
            );
            this.loading = true;
            WalletApiService.query(`/AdministrationGetRedeems`,
                params)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.$log.debug("Shop redeems response: ", response.data);
                    this.shopRedeems = response.data.content;
                    this.totalRedeems = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        headers() {
            return [
                {text: "Id", align: "start", value: "id", sortable: false},
                {text: this.$i18n.t('commonTable.shopId'), value: "shopId", sortable: false},
                {text: this.$i18n.t('commonTable.shopName'), value: "shopName", sortable: false},
                {text: this.$i18n.t('common.created'), value: "created", sortable: false},
                {text: this.$i18n.t('commonTable.status'), value: "redeemStatus", sortable: false},
                {text: this.$i18n.t('pages.membership.invoice'), value: "invoiceUrl", sortable: false}
            ]
        },
        statusesFilter() {
            return ['PENDING', 'COMPLETED', 'REJECTED', 'ANY']
        } ,
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
